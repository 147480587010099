/* Technologies.module.css */

/* Light mode */

.light-mode {
  background-color: #000000;
}

.light-mode {
  background-color: #000000;
}
.dark-mode {
  color: #ffffff;
}



